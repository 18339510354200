<template>
  <div class="create-credit-offered">
    <div class="username-content">
      <p
        v-if="currentUser"
        class="d-inline-block name-user"
      >
        {{ `${$t('views.home.welcome') } ${ currentUser.name }` }}
      </p>
      <rectoplus-button
        id="btn-redirect-offer-credit"
        class="btn-reuse-nfes"
        :text="$t('views.home.buttons.reuse_nfes')"
        @click="goToSendNFes()"
      />
    </div>
    <card-resume-offer
      v-model="resume"
      :total-value-for-material-type="totalValueForMaterialType"
      :total-quantity-for-material-type="totalQuantityForMaterialType"
      :title="$t('views.create_credit_offered.card_resume_offer')"
      @createCreditOffer="createCreditOffer"
      @createCreditOfferTarget="createCreditOfferTargetted"
    />
    <card-resume-offer
      v-model="quickSale"
      :total-value-for-material-type="totalValueForMaterialTypeQuickSale"
      :total-quantity-for-material-type="totalQuantityForMaterialTypeQuickSale"
      :title="$t('views.create_credit_offered.quick_sale')"
      @createCreditOffer="createQuickOffer"
      @createCreditOfferTarget="createTargettedQuickSale"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import CardResumeOffer from '@/components/cards/home/offer_credit/CardResumeOffer.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  components: {
    CardResumeOffer,
    RectoplusButton,
  },

  data() {
    return {
      resume: {
        paper: [],
        glass: [],
        plastic: [],
        metal: [],
      },

      quickSale: {
        paper: [],
        glass: [],
        plastic: [],
        metal: [],
      },

      maxQuantity: {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      },

      paramsToOffer: [],
    };
  },

  computed: {
    ...mapGetters([
      'getBusiness',
      'currentUser',
      'getNfesToOffer',
      'createdOffer',
      'getQuickValues',
    ]),

    totalQuantityForMaterialType() {
      const totalQuantity = {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      };

      Object.keys(this.resume)
        .forEach((key) => this.resume[key].map((nfe) => {
          if (nfe.selectedToOffer) totalQuantity[key] += Number(nfe.totalQuantity);
          return totalQuantity;
        }));

      return totalQuantity;
    },

    totalValueForMaterialType() {
      const totalValue = {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      };

      Object.keys(this.quickSale).forEach((key) => {
        const unitValue = this.fetchInfoOffer(key)?.unit_value || 0;

        totalValue[key] += this.totalQuantityForMaterialType[key] * Number(unitValue);
      });

      return totalValue;
    },

    totalQuantityForMaterialTypeQuickSale() {
      const totalQuantity = {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      };

      Object.keys(this.quickSale)
        .forEach((key) => this.quickSale[key].map((nfe) => {
          if (nfe.selectedToOffer) totalQuantity[key] += Number(nfe.totalQuantity);
          return totalQuantity;
        }));

      return totalQuantity;
    },

    totalValueForMaterialTypeQuickSale() {
      const unitValue = {
        paper: this.getQuickValues[0].paper_value,
        plastic: this.getQuickValues[0].plastic_value,
        glass: this.getQuickValues[0].glass_value,
        metal: this.getQuickValues[0].metal_value,
      };

      const totalValue = {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      };

      Object.keys(this.quickSale).forEach((key) => {
        totalValue[key] += this.totalQuantityForMaterialTypeQuickSale[key] * Number(unitValue[key]);
      });

      return totalValue;
    },
  },

  watch: {
    createdOffer(value) {
      if (value) this.goToMyOffereds();
    },
  },

  mounted() {
    if (!this.currentUser) {
      this.$store.dispatch('refreshUser');
    }

    this.fetchQuickValues();

    this.resume = {
      paper: [],
      plastic: [],
      glass: [],
      metal: [],
    };

    this.quickSale = {
      paper: [],
      plastic: [],
      glass: [],
      metal: [],
    };

    this.paramsToOffer = this.$route.params.offers;

    this.mountResumeOffer(this.getNfesToOffer);
  },

  methods: {
    ...mapActions([
      'setStepsNfe',
      'resetStateOfSuccess',
      'addErrorMessage',
      'createOffer',
      'createTargettedOffer',
      'fetchQuickValues',
    ]),

    mountOfferds(offer) {
      const offers = [];
      const materials = Object.keys(offer.resume)
        .filter((material) => offer.resume[material].length > 0);

      materials.forEach((material) => {
        const nfes = this.resume[material].filter((nfe) => nfe.selectedToOffer);
        const offersParams = this.fetchInfoOffer(material);

        if (nfes.length <= 0) return;

        offers.push({
          unit_value: offersParams.unit_value,
          material_type: offersParams.type,
          nfe_ids: nfes.map((nfe) => nfe.id),
        });
      });

      return offers;
    },

    mountQuickOffer(offer) {
      const offers = [];
      const materials = Object.keys(offer.resume)
        .filter((material) => offer.resume[material].length > 0);

      const unitValues = {
        paper: this.getQuickValues[0].paper_value,
        plastic: this.getQuickValues[0].plastic_value,
        glass: this.getQuickValues[0].glass_value,
        metal: this.getQuickValues[0].metal_value,
      };

      materials.forEach((material) => {
        const nfes = this.quickSale[material].filter((nfe) => nfe.selectedToOffer);

        if (nfes.length <= 0) return;

        offers.push({
          unit_value: unitValues[material],
          material_type: this.$t(`material_type.${material}`),
          nfe_ids: nfes.map((nfe) => nfe.id),
        });
      });

      return offers;
    },

    createCreditOffer(offer) {
      const offereds = this.mountOfferds(offer);

      if (offereds.length <= 0) {
        this.addErrorMessage(this.$t('views.create_credit_offered.error_no_offered'));
      } else {
        this.createOffer({
          business_id: this.getBusiness.id,
          password: offer.password,
          offereds,
        });
      }
    },

    createQuickOffer(offer) {
      const offereds = this.mountQuickOffer(offer);

      if (offereds.length <= 0) {
        this.addErrorMessage(this.$t('views.create_credit_offered.error_no_offered'));
      } else {
        this.createOffer({
          business_id: this.getBusiness.id,
          password: offer.password,
          offereds,
        });
      }
    },

    createCreditOfferTargetted(offer) {
      const offereds = this.mountOfferds(offer);

      if (offereds.length <= 0) {
        this.addErrorMessage(this.$t('views.create_credit_offered.error_no_offered'));
      } else {
        this.createTargettedOffer({
          business_id: this.getBusiness.id,
          password: offer.password,
          managing_entity_id: offer.managing_entity_id,
          offereds,
        });
      }
    },

    createTargettedQuickSale(offer) {
      const offereds = this.mountQuickOffer(offer);

      if (offereds.length <= 0) {
        this.addErrorMessage(this.$t('views.create_credit_offered.error_no_offered'));
      } else {
        this.createTargettedOffer({
          business_id: this.getBusiness.id,
          password: offer.password,
          managing_entity_id: offer.managing_entity_id,
          offereds,
        });
      }
    },

    goToMyOffereds() {
      this.$router.push({ name: 'my-offereds' });
    },

    translateMaterial(materialType) {
      if (materialType === 'Papel') return 'paper';
      if (materialType === 'Plástico') return 'plastic';
      if (materialType === 'Vidro') return 'glass';
      return 'metal';
    },

    calculateTotalQuantity(types, nfe) {
      const materials = {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      };

      types.forEach((type) => {
        const itens = nfe.nfe_items.filter((item) => item.material_type_name === type);

        if (itens.length > 0) {
          const materialType = this.translateMaterial(type);
          materials[materialType] = itens.reduce((total, item) => total + item.quantity, 0);
        }
      });

      return materials;
    },

    mountResumeOffer(nfes) {
      this.maxQuantity = {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      };
      this.iterateNfesToAddResume(nfes);
      this.validateIfHasNfes();
    },

    mountQuickSaleOffer(nfes) {
      this.maxQuantity = {
        paper: 0,
        plastic: 0,
        glass: 0,
        metal: 0,
      };
      this.iterateNfesQuickSale(nfes);
      this.validateIfHasNfes();
    },

    iterateNfesToAddResume(nfes) {
      nfes.forEach((nfe) => {
        const allMaterialTypes = nfe.material_type.split(', ');

        const materialsContainedInNfes = allMaterialTypes
          .filter((current, last) => allMaterialTypes.indexOf(current) === last);

        const totalQuantityOfNfe = this.calculateTotalQuantity(materialsContainedInNfes, nfe);

        materialsContainedInNfes.forEach((type) => {
          this.calculateNfesToOffers(type, totalQuantityOfNfe, nfe, materialsContainedInNfes);
        });
      });
    },

    validateIfHasNfes() {
      const hasNfes = Object.keys(this.resume).some((key) => this.resume[key].length > 0);

      if (!hasNfes) {
        this.addErrorMessage(this.$t('errors.nfes_to_offer_not_found'));
        this.$router.push({
          name: 'home',
        });
      }
    },

    findOffer(material) {
      let result = this.paramsToOffer.find((offer) => offer.type === material);

      if (!result) result = this.createOfferParams(material);

      return result;
    },

    calculateNfesToOffers(type, totalQuantityOfNfe, nfe, materialsContainedInNfes) {
      const offersParams = this.findOffer(type);
      const materialType = this.translateMaterial(type);
      const lastValue = this.maxQuantity[materialType];

      this.maxQuantity[materialType] += totalQuantityOfNfe[materialType];

      const selectNfe = this.maxQuantity[materialType] <= offersParams.quantity;

      const findNfe = this.resume[materialType]
        .find((nfeOnResume) => nfeOnResume.id === nfe.id);

      if (!findNfe) {
        this.addNfeOnResume(
          materialType,
          nfe,
          totalQuantityOfNfe[materialType],
          selectNfe,
          materialsContainedInNfes,
        );

        if (!selectNfe) this.maxQuantity[materialType] = lastValue;
      }
    },

    addNfeOnResume(type, nfe, totalQuantity, selectNfe, materialsContainedInNfes) {
      this.resume[type].push({
        ...nfe,
        totalQuantity,
        selectedToOffer: selectNfe,
        items: materialsContainedInNfes,
      });
      this.quickSale[type].push({
        ...nfe,
        totalQuantity,
        selectedToOffer: selectNfe,
        items: materialsContainedInNfes,
      });
    },

    createOfferParams(type) {
      // eslint-disable-next-line
      const quantity = window.prompt(this.$t('views.create_credit_offered.quantity_prompt', {
        type,
      }));

      // eslint-disable-next-line
      const unitValue = window.prompt(this.$t('views.create_credit_offered.unit_value_prompt', {
        type,
      }));

      if (!quantity || !unitValue) {
        this.addErrorMessage(this.$t('views.create_credit_offered.invalid_offer_params'));
        return this.$router.push({
          name: 'home',
        });
      }

      const newOffer = this.paramsToOffer.push({
        type,
        quantity: Number(quantity),
        unit_value: Number(unitValue),
        material: this.translateMaterial(type),
      });

      return this.paramsToOffer[newOffer - 1];
    },

    fetchInfoOffer(material) {
      return this.paramsToOffer.find((offer) => offer.material === material);
    },

    goToSendNFes() {
      this.setStepsNfe(1);
      this.resetStateOfSuccess();
      this.$router.push({ name: 'send-nfe' });
    },
  },
};
</script>
<style lang="scss" scoped>
  .create-credit-offered{
    background: $dashboard_background;
    min-height: 100vh;
    display: flex;
    padding: 40px 40px 40px 270px;
    flex-direction: column;

    .username-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name-user {
        font-size: 1.8em;
        font-weight: bold;
      }

      .btn-reuse-nfes {
        width: 312px;
        height: 60px;
      }
    }
  }
</style>
