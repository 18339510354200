<template>
  <div
    :class="[materialType, classBorder, 'card-info-quantity']"
  >
    <div class="card-info">
      <div :class="[materialType, 'material-type']">
        {{ $t(`material_type.${materialType}`) }}
      </div>
      <div
        v-if="hasNfes"
        :class="[materialType, 'tonne-quantity']"
      >
        <span> {{ totalQuantityFormated }} </span>
        <div class="credits-tonne">
          {{ $t('components.credit_to_offer.card_info_quantity.credits') }}
        </div>
        <div class="credits-tonne">
          {{ $t('components.credit_to_offer.card_info_quantity.tonne') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardInfoQuantity',
  props: {
    materialType: {
      type: String,
      default: '',
    },

    hasNfes: {
      type: Boolean,
      default: false,
    },

    totalQuantity: {
      type: Number,
      default: 0,
    },

    borderRadius: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    classBorder() {
      return this.borderRadius ? 'has-radios' : '';
    },

    totalQuantityFormated() {
      return this.totalQuantity.toLocaleString('pt-BR');
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-info-quantity {
    width: 35%;
    padding: 30px;
    border-bottom-left-radius: 8px;

    &.has-radius {
      border-top-left-radius: 8px;
    }

    &.paper {
      background-color: $background_card_paper;
    }

    &.plastic {
      background-color: $background_card_plastic;
    }

    &.glass {
      background-color: $background_card_glass;
    }

    &.metal {
      background-color: $background_card_metal;
    }

    .card-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 60%;

      .material-type {
        font-weight: bold;
        font-size: 1em;

        &.paper {
          color: $border_color_card_paper;
        }

        &.plastic {
          color: $border_color_card_plastic;
        }

        &.glass{
          color: $border_color_card_glass;
        }

        &.metal {
          color: $border_color_card_metal;
        }
      }

      .tonne-quantity {
        font-weight: bold;
        font-size: 1.5em;
        text-align: center;

        &.paper {
          color: $color_card_paper;
        }

        &.plastic {
          color: $color_card_plastic;
        }

        &.metal {
          color: $color_card_metal;
        }

        &.glass {
          color: $color_card_glass;
        }

        .credits-tonne {
          font-size: 0.7em;
        }
      }
    }
  }
</style>
