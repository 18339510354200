<template>
  <v-dialog
    id="password-confirmation-dialog"
    v-model="dialog"
    :change="toggleDialog()"
    class="password-dialog"
    max-width="600"
    transition="dialog-top-transition"
  >
    <div class="card-dialog">
      <div class="title-text">
        {{ $t('components.dialogs.password_confirmation.title') }}
      </div>

      <div class="text-justify">
        {{ $t('components.dialogs.password_confirmation.description') }}
      </div>

      <v-text-field
        id="passwordInput"
        v-model="password"
        class="password-confirmation-input"
        background-color="background_textarea"
        :label="$t('components.dialogs.password_confirmation.label')"
        type="password"
        flat
        solo
      />
    </div>
    <button
      class="button-dialog"
      @click="emitPassword"
    >
      {{ $t('components.dialogs.password_confirmation.confirm') }}
    </button>
  </v-dialog>
</template>
<script>

export default {
  name: 'PasswordConfirmationDialog',
  model: {
    prop: 'openDialog',
    event: 'change',
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      dialog: false,
    };
  },
  watch: {
    openDialog(value) {
      this.dialog = value;
    },
  },
  methods: {
    emitPassword() {
      this.$emit('emitPassword', { password: this.password });
      this.dialog = false;
    },
    toggleDialog() {
      this.$emit('change', this.dialog);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  padding: 40px 40px 20px 40px;
  max-height: 450px;
  border-radius: 10px;
  background: $color_light;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .title-text {
    color: $color_dark;
    font-size: 1.875em;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center;
  }
  .subtitle-text {
    text-align: center;
    color: $color_secondary;
    font-size: 1.5em;
  }

  .password-confirmation-input {
    border-radius: 10px;
    margin-top: 24px;
    width: 100%;
  }

}
.button-dialog {
  margin-top: 40px;
  border-radius: 10px;
  color: $color_light;
  font-size: 1.5em;
  font-weight: bold;
  background: $color_primary;
  height: 70px;
}
</style>
