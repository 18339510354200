import { render, staticRenderFns } from "./PasswordConfirmationDialog.vue?vue&type=template&id=64f12cfe&scoped=true"
import script from "./PasswordConfirmationDialog.vue?vue&type=script&lang=js"
export * from "./PasswordConfirmationDialog.vue?vue&type=script&lang=js"
import style0 from "./PasswordConfirmationDialog.vue?vue&type=style&index=0&id=64f12cfe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f12cfe",
  null
  
)

export default component.exports