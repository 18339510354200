<template>
  <div class="card-resume-offer">
    <div class="title-resume">
      {{ title }}
    </div>
    <div class="credit-available-grid">
      <credit-to-offer
        v-for="(materialType, index) in materialTypes"
        :key="index"
        class="credit-to-offer"
        :material-type="materialType"
        :total-quantity="totalQuantityForMaterialType[materialType]"
        :nfes="resume[materialType]"
        @selectFile="openDialogSelectNfe"
      />
    </div>

    <div
      v-for="(materialType, index) in materialTypes"
      v-show="nfesSelectedsToOffer(materialType)"
      :key="index"
    >
      <div :class="['title-material-type', materialType]">
        <span>{{ $t(`material_type.${materialType}`) }}</span>
      </div>

      <div class="total-offer-for-material-type">
        <div class="total-quantity">
          <div class="text-quantity">
            {{ $t('components.card_resume_offer.text_quantity') }}
          </div>
          <div class="quantity">
            {{ $t('components.card_resume_offer.value_total_quantity', {
              value: totalQuantityForMaterialType[materialType].toLocaleString('pt-BR'),
            }) }}
          </div>
        </div>

        <div class="total-value">
          <div class="text-value">
            {{ $t('components.card_resume_offer.text_value') }}
          </div>
          <div class="value">
            {{ formatMoney(totalValueForMaterialType[materialType]) }}
          </div>
        </div>
      </div>
    </div>

    <div class="total-offer">
      <div class="total-quantity">
        <div class="text-quantity">
          {{ $t('components.card_resume_offer.text_quantity') }}
        </div>
        <div class="quantity">
          {{ $t('components.card_resume_offer.value_total_quantity', {
            value: totalQuantityAdded.toLocaleString('pt-BR'),
          }) }}
        </div>
      </div>

      <div class="total-value">
        <div class="text-value">
          {{ $t('components.card_resume_offer.text_value') }}
        </div>
        <div class="value">
          {{ formatMoney(totalValueAdded) }}
        </div>
      </div>
    </div>

    <div class="offereds-buttons">
      <rectoplus-button-outlined
        :text="$t('components.card_resume_offer.buttons.direct_offer')"
        class="button-offered"
        @click="openBusinessDialog"
      />
      <rectoplus-button
        :text="$t('components.card_resume_offer.buttons.public_offer')"
        class="button-offered"
        elevation="0"
        @click="openDialogConfirmatePassword"
      />
    </div>

    <base-dialog-to-select-nfe
      v-model="dialogSelectNfe"
      :nfes="resume[selectedMaterialType]"
      :total-quantity="totalQuantityForMaterialType[selectedMaterialType]"
      :material-type="selectedMaterialType"
      @selectNfeToOffer="selectNfeToOffer"
    />
    <representative-industry-dialog
      v-model="dialogRepresentativeBusiness"
      @emitBusinessId="openDialogConfirmatePasswordWithBusiness"
      @selectBusiness="selectEntityManager"
    />
    <password-confirmation-dialog
      v-model="dialogConfirmPassword"
      @emitPassword="createCreditOffer"
    />
  </div>
</template>
<script>
import RepresentativeIndustryDialog from '@/components/dialogs/RepresentativeIndustryDialog.vue';
import PasswordConfirmationDialog from '@/components/dialogs/PasswordConfirmationDialog.vue';
import CreditToOffer from '@/components/cards/credit_offered/CreditToOffer.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import RectoplusButtonOutlined from '@/components/buttons/RectoplusButtonOutlined.vue';
import formatMoney from '@/mixins/formatMoney';
import BaseDialogToSelectNfe from '@/components/dialogs/BaseDialogToSelectNfe.vue';

export default {
  name: 'CardResumeOffer',
  components: {
    RepresentativeIndustryDialog,
    PasswordConfirmationDialog,
    CreditToOffer,
    RectoplusButton,
    RectoplusButtonOutlined,
    BaseDialogToSelectNfe,
  },
  mixins: [formatMoney],

  model: {
    prop: 'resume',
    event: 'change',
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    resume: {
      type: Object,
      default: () => {},
    },

    totalValueForMaterialType: {
      type: Object,
      default: () => {},
    },

    totalQuantityForMaterialType: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dialogConfirmPassword: false,
      dialogRepresentativeBusiness: false,
      dialogSelectNfe: false,
      password: '',
      selectedMaterialType: '',
      selectedEntityId: null,
    };
  },

  computed: {
    materialTypes() {
      return Object.keys(this.resume);
    },

    totalValueAdded() {
      return this.calculateTotal(this.totalValueForMaterialType);
    },

    totalQuantityAdded() {
      return this.calculateTotal(this.totalQuantityForMaterialType);
    },
  },

  methods: {
    openDialogSelectNfe(material) {
      this.selectedMaterialType = material;
      this.dialogSelectNfe = true;
    },

    nfesSelectedsToOffer(materialType) {
      return this.totalQuantityForMaterialType[materialType] !== 0;
    },

    calculateTotal(params) {
      return this.materialTypes.map((type) => params[type])
        .reduce((total, value) => total + value, 0);
    },

    selectNfeToOffer(nfe) {
      /*
        percorre todos itens de resume(papel, plástico, vidro e metal),
        depois percorre as notas desse tipo e muda o status da nfe que
        tem a identificação que que veio por parâmetro
      */
      Object.keys(this.resume).forEach((key) => {
        this.resume[key].forEach((item, index) => {
          if (item.identification === nfe.identification) {
            this.resume[key][index].selectedToOffer = nfe.select;
          }
        });
      });
    },

    openDialogConfirmatePassword() {
      this.dialogConfirmPassword = true;
    },

    openDialogConfirmatePasswordWithBusiness(params) {
      this.selectedEntityId = params.managing_entity_id;
      this.dialogConfirmPassword = true;
    },

    selectEntityManager(id) {
      this.selectedEntityId = id;
    },

    openBusinessDialog() {
      this.dialogRepresentativeBusiness = true;
    },

    createCreditOffer(payload) {
      if (this.selectedEntityId) {
        this.$emit('createCreditOfferTarget', {
          ...payload,
          resume: this.resume,
          managing_entity_id: this.selectedEntityId,
        });
      } else {
        this.$emit('createCreditOffer', {
          ...payload,
          resume: this.resume,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-resume-offer {
    margin-top: 32px;
    background: $card_nfe;
    border-radius: 10px;
    padding: 38px;

    .title-resume {
      text-align: left;
      color: $color_dark;
      font-size: 1.2em;
      font-weight: bold;
    }

    .credit-available-grid {
      margin-top: 20px;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .credit-to-offer:nth-child(1)
      .credit-to-offer:nth-child(2) {
        align-self: start;
      }

      .credit-to-offer:nth-child(3)
      .credit-to-offer:nth-child(4) {
        align-self: start;
      }
    }

    .title-material-type {
      margin: 30px 0 20px 0px;
      font-size: 1.2em;
      font-weight: bold;

      &.paper {
        color: $border_color_card_paper;
      }

      &.plastic {
        color: $border_color_card_plastic;
      }

      &.metal {
        color: $border_color_card_metal;
      }

      &.glass {
        color: $border_color_card_glass;
      }
    }

    .total-offer-for-material-type,
    .total-offer {
      margin-bottom: 40px;
      position: relative;
      display: flex;

      .total-quantity {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 56px;

        .text-quantity {
          font-size: 1.2em;
          font-weight: bold;
        }

        .quantity {
          font-size: 1.2em;
          color: $color_gray;
        }
      }

      .total-value {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .text-value {
          font-size: 1.2em;
          font-weight: bold;
        }

        .value {
          font-size: 1.2em;
          color: $color_gray;
        }
      }
    }

    .total-offer {
      height: 150px;

      &::before {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        background: $color_gray;
        opacity: 0.4;
        top: 0;
        left: 0;
      }
    }

    .offereds-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;

      .button-offered {
        margin-left: 30px;
        height: 50px;
        width: 100%;
        max-width: 300px;
      }
    }
  }
</style>
