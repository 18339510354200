<template>
  <div :class="[classToEmptyNfes, 'credit-to-offer']">
    <card-info-quantity
      :material-type="materialType"
      :has-nfes="hasNfes"
      :total-quantity="totalQuantity"
    />

    <div :class="[materialType, 'nfes-available']">
      <div class="title-nfes">
        {{ $t('components.credit_to_offer.title_nfes_available') }}
      </div>
      <div
        v-if="hasNfes"
        :class="[materialType, 'nfes-content']"
      >
        <base-file-nfe
          v-for="(nfe, index) in nfes"
          v-show="nfe.selectedToOffer"
          :key="index"
          :nfe="nfe"
          :material-type="materialType"
        />
      </div>
      <div
        v-if="hasNfes"
        :class="[materialType, 'text-show-credits']"
        @click="selectFiles"
      >
        {{ $t('components.credit_to_offer.show_credits') }}
      </div>
    </div>
  </div>
</template>
<script>
import CardInfoQuantity from '@/components/cards/credit_offered/credit_to_offer_components/CardInfoQuantity.vue';
import BaseFileNfe from '@/components/cards/credit_offered/credit_to_offer_components/BaseFileNfe.vue';

export default {
  name: 'CreditToOffer',
  components: {
    CardInfoQuantity,
    BaseFileNfe,
  },
  props: {
    materialType: {
      type: String,
      default: '',
    },

    nfes: {
      type: Array,
      default: () => [],
    },

    totalQuantity: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    hasNfes() {
      return this.nfes.length > 0;
    },

    classToEmptyNfes() {
      return !this.hasNfes ? 'empty-nfes' : '';
    },
  },

  methods: {
    selectFiles() {
      this.$emit('selectFile', this.materialType);
    },
  },
};
</script>
<style lang="scss" scoped>
  .credit-to-offer {
    display: flex;
    max-width: 632px;
    width: 100%;

    &.empty-nfes {
      height: 80px;
    }

    .nfes-available {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 65%;
      border-radius: 0 8px 8px 0;
      padding: 30px;

      &.paper {
        background-color: $background_list_nfes_file_paper;
      }

      &.plastic {
        background-color: $background_list_nfes_file_plastic;
      }

      &.glass {
        background-color: $background_list_nfes_file_glass;
      }

      &.metal {
        background-color: $background_list_nfes_file_metal;
      }

      .title-nfes {
        text-align: center;
        font-size: 1em;
        color: $color_gray;
        font-weight: bold;
      }

      .nfes-content {
        margin-top: 20px;
        overflow-y: auto;
        height: 200px;

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          background: $files_backgrond;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          border: 0.1875em solid $files_backgrond;
        }

        &.paper {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_paper;
          }
        }

        &.plastic {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_plastic;
          }
        }

        &.glass {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_glass;
          }
        }

        &.metal {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_metal;
          }
        }
      }

      .text-show-credits {
        text-decoration: underline;
        margin-top: 26px;
        cursor: pointer;

        &.paper {
          color: $border_color_card_paper;
        }

        &.plastic {
          color: $border_color_card_plastic;
        }

        &.glass{
          color: $border_color_card_glass;
        }

        &.metal {
          color: $border_color_card_metal;
        }
      }
    }
  }
</style>
