<template>
  <div
    class="base-file-nfe"
    :class="classHasCheckBox"
  >
    <div class="icon">
      <v-icon
        size="20"
        class="mr-2"
      >
        mdi-file-outline
      </v-icon>
    </div>
    <div>
      <div :class="[{ 'dialog': isDialog }, 'nfe-identification text-truncate']">
        {{ $t('components.base_file_nfe.file_name', { name: nfe.identification }) }}
      </div>
      <div :class="[materialType, 'nfe-issue-date']">
        {{ nfe.issue_date }}
      </div>
      <div :class="[materialType, 'mass-quantity']">
        {{ $t('components.base_file_nfe.quantity', { value: nfe.totalQuantity }) }}
      </div>
    </div>
    <div v-if="enableSelection">
      <!--
        Parece que as dialogs compartilhavam do
        mesmo v-model quando ele era uma variavel no data.
        Para resolver isso, foi feito um metodo
        que muda o status de seleção da nota
        e passa esse valor para o checkbox.
      -->
      <v-checkbox
        v-model="nfeSelected"
        color="primary"
        hide-details
        readonly
        @click="toggleSelected"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseFileNfe',

  props: {
    materialType: {
      type: String,
      default: '',
    },

    nfe: {
      type: Object,
      default: () => {},
    },

    enableSelection: {
      type: Boolean,
      default: false,
    },

    isDialog: {
      type: Boolean,
      default: false,
    },

    nfeSelected: {
      type: Boolean,
      default: false,
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    classHasCheckBox() {
      return this.enableSelection ? 'with-select' : '';
    },

    totalQuantityFormated() {
      return this.nfe.totalQuantity.toLocaleString('pt-BR');
    },
  },

  methods: {
    toggleSelected() {
      this.$emit('selectNfeToOffer', {
        index: this.index,
        select: !this.nfeSelected,
        materialType: this.materialType,
        items: this.nfe.items,
        identification: this.nfe.identification,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .base-file-nfe {
    margin-bottom: 20px;
    display: flex;

    &.with-select {
      justify-content: space-evenly;
      align-items: center;
    }

    .nfe-identification {
      font-size: 1em;
      color: $color_gray;
      width: 130px;

      &.dialog {
        width: 450px;
      }
    }

    .mass-quantity {
      font-size: 0.8em;

      &.paper {
        color: $border_color_card_paper;
      }

      &.plastic {
        color: $border_color_card_plastic;
      }

      &.glass{
        color: $border_color_card_glass;
      }

      &.metal {
        color: $border_color_card_metal;
      }
    }

    .nfe-issue-date {
      font-size: 0.8em;

      &.paper {
        color: $border_color_card_paper;
      }

      &.plastic {
        color: $border_color_card_plastic;
      }

      &.glass{
        color: $border_color_card_glass;
      }

      &.metal {
        color: $border_color_card_metal;
      }
    }
  }
</style>
