<template>
  <div
    :class="[{ active: selected }, 'content']"
    @click="selectBusiness"
  >
    <span>
      {{ business.name }}
    </span>
    <v-icon
      v-if="selected"
      color="primary"
    >
      mdi-check-circle
    </v-icon>
  </div>
</template>
<script>

export default {
  name: 'BusinessList',
  props: {
    business: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectBusiness() {
      this.$emit('selectBusinesToOffer', this.business.id);
    },
  },
};
</script>
<style lang="scss" scoped>
  .content {
    font-size: 1em;
    padding: 30px;
    background-color: $app_background;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: $color_gray;
      opacity: 0.5;
    }
  }
  .active {
    font-size: 1em;
    background-color: $background_business_select;
    display: flex;
    justify-content: space-between;
  }
</style>
