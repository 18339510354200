<template>
  <v-dialog
    id="representative-industry-dialog"
    v-model="dialog"
    :change="toggleDialog()"
    class="password-dialog"
    max-width="1000"
    max-height="1000"
    transition="dialog-top-transition"
  >
    <div class="card-dialog">
      <div class="background-header">
        <div class="title-text">
          {{ $t('components.representative_industry_dialog.title') }}
        </div>
      </div>

      <div class="card-subtitle">
        {{ $t('components.representative_industry_dialog.subtitle') }}
      </div>
      <div class="business-content">
        <div v-if="hasBusiness">
          <business-list
            v-for="(business, index) in getAllBusiness"
            :key="index"
            :selected="business.id == managingEntityId"
            :business="business"
            @selectBusinesToOffer="selectBusinesToOffer"
          />
        </div>
        <div v-else>
          <div class="no-business">
            {{ $t('components.representative_industry_dialog.no_business') }}
          </div>
        </div>
      </div>
      <div class="dialog-buttons">
        <rectoplus-button-outlined
          class="outlined-button"
          :text="$t('components.representative_industry_dialog.cancel')"
          @click="closeDialog"
        />
        <rectoplus-button
          :text="$t('components.dialogs.password_confirmation.confirm')"
          class="button-dialog"
          elevation="0"
          @click="emitBusinessId"
        />
      </div>
    </div>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButtonOutlined from '@/components/buttons/RectoplusButtonOutlined.vue';
import BusinessList from '@/components/cards/business/BusinessList.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'RepresentativeIndustryDialog',
  components: {
    RectoplusButtonOutlined,
    BusinessList,
    RectoplusButton,
  },
  model: {
    prop: 'openDialog',
    event: 'change',
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      managingEntityId: '',
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(['getAllBusiness']),
    hasBusiness() {
      return this.getAllBusiness.length > 0;
    },
  },
  watch: {
    openDialog(value) {
      this.dialog = value;
    },
  },
  mounted() {
    this.indexBusiness({ by_kind: 'representative_business_industries' });
  },
  methods: {
    ...mapActions(['indexBusiness']),

    emitBusinessId() {
      this.$emit('emitBusinessId', { managing_entity_id: this.managingEntityId });
      this.dialog = false;
    },

    toggleDialog() {
      this.$emit('change', this.dialog);
    },

    closeDialog() {
      this.dialog = false;
    },

    selectBusinesToOffer(entityId) {
      this.managingEntityId = entityId;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  border-radius: 10px;
  background: $color_light;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .background-header {
    background-color: $color_primary;
    width: 100%;

    .title-text {
      padding: 30px 30px 20px 30px;
      font-size: 1.3em;
      color: $color_white;
      margin-bottom: 30px;
      font-weight: bold;
      text-align: initial;
    }

  }
  .card-subtitle {
    padding: 0px 20px 20px 30px;
    font-size: 1.3em;
  }

  .subtitle-text {
    text-align: center;
    color: $color_secondary;
    font-size: 1.5em;
  }

  .business-content {
    max-height: 20vw;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: $files_backgrond;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color_primary;
      border-radius: 20px;
      border: 0.1875em solid $files_backgrond;
    }
  }

  .no-business {
    text-align: center;
    color: $color_secondary;
    font-size: 1.5em;
  }

  .password-confirmation-input {
    border-radius: 10px;
    margin-top: 24px;
    width: 100%;
  }

}
.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  margin: 20px;

  .outlined-button {
    margin-right: 20px;
    height: 58px;
    width: 300px;
  }

  .button-dialog {
    height: 58px;
    width: 300px;
  }
}
</style>
