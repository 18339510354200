<template>
  <v-dialog
    v-model="dialog"
    :change="toggleDialog()"
    class="base-dialog-to-select-nfe"
    max-width="1000"
    transition="dialog-top-transition"
  >
    <div class="card-dialog">
      <div class="title-text">
        {{ $t('components.dialogs.budget_request.title') }}
      </div>

      <div class="nfes-content">
        <card-info-quantity
          :material-type="materialType"
          :has-nfes="true"
          :border-radius="false"
          :total-quantity="totalQuantity"
        />
        <div
          :class="[materialType, 'nfe-list']"
        >
          <div class="title-list">
            {{ $t('components.credit_to_offer.title_nfes_available') }}
          </div>
          <div
            :class="[materialType, 'nfes-files']"
          >
            <base-file-nfe
              v-for="(nfe, index) in nfes"
              :key="index"
              :nfe="nfe"
              :index="index"
              :nfe-selected="nfe.selectedToOffer"
              :material-type="materialType"
              :enable-selection="true"
              :is-dialog="true"
              @selectNfeToOffer="selectNfeToOffer"
            />
          </div>
        </div>
      </div>
    </div>

    <button
      class="button-dialog"
      @click="closeDialog"
    >
      {{ $t('components.credit_to_offer.button') }}
    </button>
  </v-dialog>
</template>
<script>
import CardInfoQuantity from '@/components/cards/credit_offered/credit_to_offer_components/CardInfoQuantity.vue';
import BaseFileNfe from '@/components/cards/credit_offered/credit_to_offer_components/BaseFileNfe.vue';

export default {
  name: 'BaseDialogToSelectNfe',
  components: { CardInfoQuantity, BaseFileNfe },

  model: {
    prop: 'openDialog',
    event: 'change',
  },

  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },

    nfes: {
      type: Array,
      default: () => [],
    },

    materialType: {
      type: String,
      default: '',
    },

    totalQuantity: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  watch: {
    openDialog(value) {
      this.dialog = value;
    },
  },

  methods: {
    toggleDialog() {
      this.$emit('change', this.dialog);
    },

    closeDialog() {
      this.dialog = false;
    },

    selectNfeToOffer(nfe) {
      this.$emit('selectNfeToOffer', nfe);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  .title-text {
    background-color: $color_primary;
    padding: 20px;
    font-weight: bold;
    color: $color_white;
  }

  .nfes-content {
    display: flex;
    height: calc(67vh - 33px);

    .nfe-list {
      width: 65%;
      padding: 30px;
      border-bottom-right-radius: 8px;

      &.paper {
        background-color: $background_list_nfes_file_paper;
      }

      &.plastic {
        background-color: $background_list_nfes_file_plastic;
      }

      &.glass {
        background-color: $background_list_nfes_file_glass;
      }

      &.metal {
        background-color: $background_list_nfes_file_metal;
      }

      .title-list {
        color: $color_gray;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
      }

      .nfes-files {
        margin-top: 20px;
        overflow-y: auto;
        height: calc(100vh - 450px);

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          background: $files_backgrond;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          border: 0.1875em solid $files_backgrond;
        }

        &.paper {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_paper;
          }
        }

        &.plastic {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_plastic;
          }
        }

        &.glass {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_glass;
          }
        }

        &.metal {
          &::-webkit-scrollbar-thumb {
            background-color: $border_color_card_metal;
          }
        }
      }
    }
  }
}

.button-dialog {
  margin-top: 40px;
  border-radius: 10px;
  color: $color_light;
  font-size: 1.5em;
  font-weight: bold;
  background: $color_primary;
  height: 70px;
}
</style>
